import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
// fonction
// BTC
export var btcMiniPrice = function btcMiniPrice(data) {
  if (data) {
    var prices = data.map(function (_ref) {
      var price = _ref.price;
      return price;
    });
    var dataBTC = data.map(function (_ref2) {
      var timestamp = _ref2.timestamp,
          price = _ref2.price;
      return [timestamp * 1000, price];
    });
    var option = {
      toolbox: {
        show: false
      },
      xAxis: [{
        show: false,
        type: 'time',
        axisLine: {
          onZero: false
        }
      }],
      yAxis: [{
        show: false,
        type: 'value',
        max: Math.max.apply(Math, _toConsumableArray(prices)) + 1,
        min: Math.min.apply(Math, _toConsumableArray(prices)) - 1,
        axisLine: {
          onZero: false
        }
      }],
      calculable: true,
      tooltip: {
        trigger: 'axis'
      },
      series: [{
        name: 'Prix en $',
        type: 'line',
        connectNulls: false,
        color: "#f2a900",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f2a900",
              shadowColor: 'rgba(0,0,0,0.4)'
            }
          }
        },
        data: dataBTC,
        showSymbol: false
      }]
    };
    console.log("miniPrice", option);
    return option;
  }

  return {
    option: {}
  };
};
export var btcMiniNetwork = function btcMiniNetwork(data) {
  if (data) {
    var dataBTC = data.map(function (_ref3) {
      var x = _ref3.x,
          y = _ref3.y;
      return [x * 1000, Math.round(y)];
    });
    var vals = data.map(function (_ref4) {
      var y = _ref4.y;
      return Math.round(y);
    });
    var option = {
      toolbox: {
        show: false
      },
      xAxis: [{
        show: false,
        type: 'time',
        axisLine: {
          onZero: false
        }
      }],
      yAxis: [{
        show: false,
        type: 'value',
        max: Math.max.apply(Math, _toConsumableArray(vals)),
        min: Math.min.apply(Math, _toConsumableArray(vals)),
        axisLine: {
          onZero: false
        }
      }],
      calculable: true,
      tooltip: {
        trigger: 'axis'
      },
      series: [{
        name: 'Difficulté',
        type: 'bar',
        connectNulls: false,
        itemStyle: {
          color: "#f2a900",
          shadowColor: 'rgba(0,0,0,0.4)'
        },
        data: dataBTC,
        showSymbol: false
      }]
    };
    return option;
  }

  return {
    option: {}
  };
};
export var btcHashrate = function btcHashrate(data) {
  if (data) {
    var dataBTC = data.map(function (_ref5) {
      var x = _ref5.x,
          y = _ref5.y;
      return [x * 1000, Math.round(y / 1000000)];
    });
    var option = {
      xAxis: {
        type: "time"
      },
      yAxis: {
        type: "value",
        name: "EH/S"
      },
      series: [{
        data: dataBTC,
        name: "EH/S",
        type: "line",
        areaStyle: {},
        color: "#f2a900",
        showSymbol: false
      }],
      calculable: true,
      tooltip: {
        trigger: 'axis'
      }
    };
    return option;
  }

  return {
    option: {}
  };
};
export var btcEarn = function btcEarn(data) {
  console.log("data", data);

  if (data) {
    var seriesBTC = data[1].data.map(function (x) {
      return [x.t, Number(Number(x.y * 1000000).toFixed(2))];
    });
    var seriesUSD = data[2].data.map(function (x) {
      return [x.t, Number(Number(x.y).toFixed(3))];
    });
    var valuesBTC = seriesBTC.map(function (x) {
      return x[1];
    });
    var valuesUSD = seriesUSD.map(function (x) {
      return x[1];
    });
    console.log("seriesBTC", seriesBTC);
    console.log("seriesUSD", seriesUSD);
    var option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      legend: {
        data: ['Rev. μBTC', 'Rev. USD'],
        textStyle: {
          color: '#fff'
        }
      },
      xAxis: [{
        type: 'time',
        axisPointer: {
          type: 'shadow'
        }
      }],
      yAxis: [{
        type: 'value',
        name: 'Rev. μBTC',
        axisLabel: {
          formatter: '{value} µBTC',
          color: '#fff'
        },
        max: Math.max.apply(Math, _toConsumableArray(valuesBTC)),
        min: Math.min.apply(Math, _toConsumableArray(valuesBTC))
      }, {
        type: 'value',
        name: 'Rev. USD',
        axisLabel: {
          formatter: '{value} $',
          color: '#fff'
        },
        max: Math.max.apply(Math, _toConsumableArray(valuesUSD)),
        min: Math.min.apply(Math, _toConsumableArray(valuesUSD))
      }],
      series: [{
        name: 'Rev. μBTC',
        type: 'line',
        tooltip: {
          valueFormatter: function valueFormatter(value) {
            return value + ' μBTC';
          }
        },
        color: "#f2a900",
        lineStyle: {
          normal: {
            color: "#f2a900",
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          }
        },
        data: seriesBTC,
        showSymbol: false
      }, {
        name: 'Rev. USD',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function valueFormatter(value) {
            return value + ' $';
          }
        },
        color: "#7e8187",
        lineStyle: {
          normal: {
            color: "#ffffff",
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          }
        },
        data: seriesUSD,
        showSymbol: false
      }]
    };
    return option;
  }

  return {
    option: {}
  };
}; // ETH

export var ethMiniPrice = function ethMiniPrice(data) {
  if (data) {
    var prices = data.map(function (_ref6) {
      var price = _ref6.price;
      return price;
    });
    var dataBTC = data.map(function (_ref7) {
      var timestamp = _ref7.timestamp,
          price = _ref7.price;
      return [timestamp * 1000, price];
    });
    var option = {
      toolbox: {
        show: false
      },
      xAxis: [{
        show: false,
        type: 'time',
        axisLine: {
          onZero: false
        }
      }],
      yAxis: [{
        show: false,
        type: 'value',
        max: Math.max.apply(Math, _toConsumableArray(prices)) + 1,
        min: Math.min.apply(Math, _toConsumableArray(prices)) - 1,
        axisLine: {
          onZero: false
        }
      }],
      calculable: true,
      tooltip: {
        trigger: 'axis'
      },
      series: [{
        name: 'Prix en $',
        type: 'line',
        connectNulls: false,
        color: "#37367b",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#37367b",
              shadowColor: 'rgba(0,0,0,0.4)'
            }
          }
        },
        data: dataBTC,
        showSymbol: false
      }]
    };
    return option;
  }

  return {
    option: {}
  };
};
export var ethMiniNetwork = function ethMiniNetwork(data) {
  console.log("data", data);

  if (data) {
    var vals = data.map(function (x) {
      return x[1];
    });
    var option = {
      toolbox: {
        show: false
      },
      xAxis: [{
        show: false,
        type: 'time',
        axisLine: {
          onZero: false
        }
      }],
      yAxis: [{
        show: false,
        type: 'value',
        max: Math.max.apply(Math, _toConsumableArray(vals)),
        min: Math.min.apply(Math, _toConsumableArray(vals)),
        axisLine: {
          onZero: false
        }
      }],
      calculable: true,
      tooltip: {
        trigger: 'axis'
      },
      series: [{
        name: 'Difficulté (en P)',
        type: 'bar',
        connectNulls: false,
        itemStyle: {
          color: "#37367b",
          shadowColor: 'rgba(0,0,0,0.4)'
        },
        data: data,
        showSymbol: false
      }]
    };
    return option;
  } else {
    return {
      option: {}
    };
  }
};
export var ethHashrate = function ethHashrate(data) {
  console.log("data", data);

  if (data) {
    var vals = data.map(function (x) {
      return x[1];
    });
    var option = {
      xAxis: {
        type: "time",
        axisLine: {
          onZero: false
        }
      },
      yAxis: {
        type: "value",
        name: "TH/S",
        min: Math.min.apply(Math, _toConsumableArray(vals)),
        max: Math.max.apply(Math, _toConsumableArray(vals))
      },
      series: [{
        data: data,
        name: "TH/S",
        type: "line",
        areaStyle: {},
        color: "#37367b",
        showSymbol: false
      }],
      calculable: true,
      tooltip: {
        trigger: 'axis'
      }
    };
    return option;
  } else {
    return {
      option: {}
    };
  }
};
export var ethEarn = function ethEarn(data) {
  console.log("data", data);

  if (data) {
    var seriesBTC = data[1].data.map(function (x) {
      return [x.t, Number(Number(x.y * 1000000).toFixed(2))];
    });
    var seriesUSD = data[2].data.map(function (x) {
      return [x.t, Number(Number(x.y).toFixed(3))];
    });
    var valuesBTC = seriesBTC.map(function (x) {
      return x[1];
    });
    var valuesUSD = seriesUSD.map(function (x) {
      return x[1];
    });
    console.log("seriesBTC", seriesBTC);
    console.log("seriesUSD", seriesUSD);
    var option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      legend: {
        data: ['Rev. μBTC', 'Rev. USD'],
        textStyle: {
          color: '#fff'
        }
      },
      xAxis: [{
        type: 'time',
        axisPointer: {
          type: 'shadow'
        }
      }],
      yAxis: [{
        type: 'value',
        name: 'Rev. μBTC',
        axisLabel: {
          formatter: '{value} µBTC',
          color: '#fff'
        },
        max: Math.max.apply(Math, _toConsumableArray(valuesBTC)),
        min: Math.min.apply(Math, _toConsumableArray(valuesBTC))
      }, {
        type: 'value',
        name: 'Rev. USD',
        axisLabel: {
          formatter: '{value} $',
          color: '#fff'
        },
        max: Math.max.apply(Math, _toConsumableArray(valuesUSD)),
        min: Math.min.apply(Math, _toConsumableArray(valuesUSD))
      }],
      series: [{
        name: 'Rev. μBTC',
        type: 'line',
        tooltip: {
          valueFormatter: function valueFormatter(value) {
            return value + ' μBTC';
          }
        },
        color: "#37367b",
        lineStyle: {
          normal: {
            color: "#37367b",
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          }
        },
        data: seriesBTC,
        showSymbol: false
      }, {
        name: 'Rev. USD',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function valueFormatter(value) {
            return value + ' $';
          }
        },
        color: "#7e8187",
        lineStyle: {
          normal: {
            color: "#ffffff",
            shadowColor: 'rgba(0, 0, 0, .3)',
            shadowBlur: 0,
            shadowOffsetY: 5,
            shadowOffsetX: 5
          }
        },
        data: seriesUSD,
        showSymbol: false
      }]
    };
    return option;
  }

  return {
    option: {}
  };
};