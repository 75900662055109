import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { generateProductUrl } from "../../../core/utils";
import removeImg from "../../../images/garbage.svg";

var ProductList = function ProductList(_ref) {
  var lines = _ref.lines,
      remove = _ref.remove;
  return React.createElement("ul", {
    className: "cart__list"
  }, lines.map(function (line, index) {
    var productUrl = generateProductUrl(line.variant.product.id, line.variant.product.name);
    var key = line.id ? "id-".concat(line.id) : "idx-".concat(index);
    return React.createElement("li", {
      key: key,
      className: "cart__list__item",
      "data-test": "cartRow",
      "data-test-id": line.variant.sku
    }, React.createElement(Link, {
      to: productUrl
    }, React.createElement(Thumbnail, {
      source: line.variant.product
    })), React.createElement("div", {
      className: "cart__list__item__details"
    }, React.createElement("p", {
      "data-test": "price"
    }, React.createElement(TaxedMoney, {
      taxedMoney: line.variant.pricing.price
    })), React.createElement(Link, {
      to: productUrl
    }, React.createElement("p", {
      "data-test": "name"
    }, line.variant.product.name)), React.createElement("span", {
      className: "cart__list__item__details__variant"
    }, React.createElement("span", null, line.variant.name), React.createElement("span", {
      "data-test": "quantity"
    }, React.createElement("div", {
      className: "row"
    }, React.createElement("div", {
      className: "col"
    }, React.createElement(ReactSVG, {
      path: removeImg,
      className: "sc-dVhcbM eYWclR",
      "data-test": "deleteButton",
      style: {
        fill: "white"
      },
      onClick: function onClick() {
        return remove(line.variant.id);
      }
    })), React.createElement("div", {
      className: "col",
      style: {
        width: "70px"
      }
    }, React.createElement(FormattedMessage, {
      id: "components.OverlayManager.Cart.ProductList.3897981513",
      defaultMessage: "Qty: {quantity}",
      values: {
        quantity: line.quantity
      }
    })))))));
  }));
};

export default ProductList;