import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-grow: 1;\n  overflow: hidden;\n\n  > img {\n    flex-grow: 1;\n    object-fit: contain;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 700;\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  ", "\n  color: #a3ff12;\n  font-weight: 800;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    padding: 1.8rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background: #151515;\n  color: #ffffff;\n  border-radius: 6px;\n  padding: 2.5rem;\n  text-align: center;\n  height: 26rem;\n  display: flex;\n  flex-direction: column;\n  transition: 0.3s;\n  box-shadow:\n  0px 6px 10px 0px hsla(0,0%,0%,0.16),\n  0px 1px 18px 0px hsla(0,0%,0%,0.12),\n  0px 3px 5px -1px hsla(0,0%,0%,0.22);\n\n  :hover {\n    background-color: #181818;\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: left;\n  color: #ffffff;\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from "styled-components";
import { media, styled } from "@styles";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2(), media.largeScreen(_templateObject3()));
export var Title = styled.h4(_templateObject4(), textProps);
export var Price = styled.p(_templateObject5(), textProps);
export var Image = styled.div(_templateObject6());