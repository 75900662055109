import "../../../globalStyles/scss/index.scss";
import React from "react";

var Hosted = function Hosted() {
  return React.createElement("div", {
    className: "hosted-top"
  }, React.createElement("div", {
    className: "parallax"
  }, React.createElement("div", {
    className: "parallax__layer parallax__layer--back"
  }, React.createElement("div", {
    className: "page-heading page-heading--default text-center w-100"
  }, React.createElement("h1", {
    className: "hosted-font-intro"
  }, "HOSTED MINING"))), React.createElement("div", {
    className: "parallax__layer parallax__layer--base"
  }, React.createElement("h4", {
    className: "center hosted-pad  text-center"
  }, "Mining Market vous propose l'hosting de vos machines dans une de nos fermes de mining."), React.createElement("p", {
    className: "font-hosted-w text-center"
  }, "Nous nous occupons pour vous du d\xE9ploiement, de la gestion et de la maintenance compl\xE8te."), React.createElement("div", {
    className: "row text-center hosted-mar"
  }, React.createElement("div", {
    className: "col-md-4"
  }, React.createElement("img", {
    className: "img-hosted",
    src: "/assets/img/icons-hosted/machine.png"
  }), React.createElement("p", {
    className: "font-hosted-w"
  }, "Votre machine vous apartient")), React.createElement("div", {
    className: "col-md-4"
  }, React.createElement("img", {
    className: "img-hosted",
    src: "/assets/img/icons-hosted/energy.png"
  }), React.createElement("p", {
    className: "font-hosted-w"
  }, "Aliment\xE9 avec de l'\xE9nergie verte")), React.createElement("div", {
    className: "col-md-4"
  }, React.createElement("img", {
    className: "img-hosted",
    src: "/assets/img/icons-hosted/cout.png"
  }), React.createElement("p", {
    className: "font-hosted-w"
  }, "Cout de l'\xE9lectricit\xE9 r\xE9duit"))), React.createElement("div", {
    className: "row text-center hosted-mar mb-5"
  }, React.createElement("div", {
    className: "col-md-4"
  }, React.createElement("img", {
    className: "img-hosted",
    src: "/assets/img/icons-hosted/qualite.png"
  }), React.createElement("p", {
    className: "font-hosted-w"
  }, "Datacenter nouvelle generation")), React.createElement("div", {
    className: "col-md-4"
  }, React.createElement("img", {
    className: "img-hosted",
    src: "/assets/img/icons-hosted/cooling.png"
  }), React.createElement("p", {
    className: "font-hosted-w"
  }, "Refroidissement haute performance")), React.createElement("div", {
    className: "col-md-4"
  }, React.createElement("img", {
    className: "img-hosted",
    src: "/assets/img/icons-hosted/dispo.png"
  }), React.createElement("p", {
    className: "font-hosted-w"
  }, "Personnel disponible 24/7"))))));
};

export { Hosted };