import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 80%;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 1.5rem;\n  margin-bottom: 4rem;\n  padding: 0;\n\n  font-weight: ", ";\n  font-size: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  overflow-y: auto;\n  overflow-x: hidden;\n  width: 300px;\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  align-items: center;\n  color: #ffffff;\n  background-color: #151515;\n  box-shadow: 6px 0px 30px rgba(0, 0, 0, 0.15);\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Header = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h3FontSize;
});