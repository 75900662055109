import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.5rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  min-height: 60px;\n  max-height: min-content;\n  width: 100%;\n  grid-template-areas: \"products price quantity totalPrice\";\n  grid-template-columns: 2.5fr 1.1fr 1.1fr 1.3fr;\n  align-items: center;\n  font-size: ", ";\n  color: #a3ff12;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Column = styled.div(_templateObject2());