import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  > div {\n    margin: 0.4rem;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 12rem;\n  display: inline-block;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0.6rem;\n  color: #a3ff12;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 2rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding-left: 2rem;\n  cursor: pointer;\n  font-size: ", ";\n  color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  height: 1.2rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  height: 5rem;\n  background-color: #151515;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 2rem;\n  font-size: ", ";\n  margin-top: 1rem;\n  margin-bottom: 1.4rem;\n  border-radius: 6px;\n  color: #ffffff;\n  box-shadow:\n  0px 6px 10px 0px hsla(0,0%,0%,0.14),\n  0px 1px 18px 0px hsla(0,0%,0%,0.12),\n  0px 3px 5px -1px hsla(0,0%,0%,0.2);\n\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 1.4rem;\n  color: #ffffff;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Bar = styled.div(_templateObject2(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var LeftSide = styled.div(_templateObject3());
export var RightSide = styled.div(_templateObject4());
export var FiltersButton = styled.button(_templateObject5(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Clear = styled.button(_templateObject6(), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
});
export var Element = styled.span(_templateObject7());
export var Filters = styled.span(_templateObject8(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Label = styled.span(_templateObject9(), function (props) {
  return props.theme.colors.lightFont;
});
export var Sort = styled.div(_templateObject10());
export var FiltersChipsWrapper = styled.div(_templateObject11());