import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import QuantityInput from "../../molecules/QuantityInput";
import AddToCartButton from "../../molecules/AddToCartButton";
import ProductVariantPicker from "../ProductVariantPicker";
import * as S from "./styles";
import { getAvailableQuantity, canAddToCart } from "./stockHelpers";
var LOW_STOCK_QUANTITY = 5;

var AddToCartSection = function AddToCartSection(_ref) {
  var availableForPurchase = _ref.availableForPurchase,
      isAvailableForPurchase = _ref.isAvailableForPurchase,
      items = _ref.items,
      name = _ref.name,
      productPricing = _ref.productPricing,
      productVariants = _ref.productVariants,
      queryAttributes = _ref.queryAttributes,
      onAddToCart = _ref.onAddToCart,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      setVariantId = _ref.setVariantId,
      variantId = _ref.variantId;
  var intl = useIntl();

  var _useState = useState(1),
      _useState2 = _slicedToArray(_useState, 2),
      quantity = _useState2[0],
      setQuantity = _useState2[1];

  var _useState3 = useState(0),
      _useState4 = _slicedToArray(_useState3, 2),
      variantStock = _useState4[0],
      setVariantStock = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      variantPricing = _useState6[0],
      setVariantPricing = _useState6[1];

  var availableQuantity = getAvailableQuantity(items, variantId, variantStock);
  var isOutOfStock = !!variantId && variantStock === 0;
  var noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  var purchaseAvailableDate = !isAvailableForPurchase && availableForPurchase && Date.parse(availableForPurchase);
  var isNoItemsAvailable = !!variantId && !isOutOfStock && !availableQuantity;
  var isLowStock = !!variantId && !isOutOfStock && !isNoItemsAvailable && availableQuantity < LOW_STOCK_QUANTITY;
  var disableButton = !canAddToCart(items, !!isAvailableForPurchase, variantId, variantStock, quantity);

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, {
      "data-test": "stockErrorMessage",
      "data-testId": testingContextId
    }, message);
  };

  var onVariantPickerChange = function onVariantPickerChange(_selectedAttributesValues, selectedVariant) {
    if (!selectedVariant) {
      setVariantId("");
      setVariantPricing(null);
      setVariantStock(0);
      return;
    }

    setVariantId(selectedVariant.id);
    setVariantPricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.pricing);
    setVariantStock(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.quantityAvailable);
  };

  return (// <S.AddToCartSelection>
    //   <S.ProductNameHeader data-test="productName">{name}</S.ProductNameHeader>
    //   {isOutOfStock ? (
    //     renderErrorMessage(
    //       intl.formatMessage(commonMessages.outOfStock),
    //       "outOfStock"
    //     )
    //   ) : (
    //     <S.ProductPricing>
    //       {getProductPrice(productPricing, variantPricing)}
    //     </S.ProductPricing>
    //   )}
    //   {noPurchaseAvailable &&
    //     renderErrorMessage(
    //       intl.formatMessage(commonMessages.noPurchaseAvailable),
    //       "notAvailable"
    //     )}
    //   {purchaseAvailableDate &&
    //     renderErrorMessage(
    //       intl.formatMessage(commonMessages.purchaseAvailableOn, {
    //         date: new Intl.DateTimeFormat("default", {
    //           year: "numeric",
    //           month: "numeric",
    //           day: "numeric",
    //         }).format(purchaseAvailableDate),
    //         time: new Intl.DateTimeFormat("default", {
    //           hour: "numeric",
    //           minute: "numeric",
    //         }).format(purchaseAvailableDate),
    //       }),
    //       "timeRestrictedAvailability"
    //     )}
    //   {isLowStock &&
    //     renderErrorMessage(
    //       intl.formatMessage(commonMessages.lowStock),
    //       "lowStockWarning"
    //     )}
    //   {isNoItemsAvailable &&
    //     renderErrorMessage(
    //       intl.formatMessage(commonMessages.noItemsAvailable),
    //       "noItemsAvailable"
    //     )}
    //   <S.VariantPicker>
    //     <ProductVariantPicker
    //       productVariants={productVariants}
    //       onChange={onVariantPickerChange}
    //       selectSidebar
    //       queryAttributes={queryAttributes}
    //       onAttributeChangeHandler={onAttributeChangeHandler}
    //     />
    //   </S.VariantPicker>
    //   <S.QuantityInput>
    //     <QuantityInput
    //       quantity={quantity}
    //       maxQuantity={availableQuantity}
    //       disabled={isOutOfStock || isNoItemsAvailable}
    //       onQuantityChange={setQuantity}
    //       hideErrors={!variantId || isOutOfStock || isNoItemsAvailable}
    //       testingContext="addToCartQuantity"
    //     />
    //   </S.QuantityInput>
    //   <AddToCartButton
    //     onSubmit={() => onAddToCart(variantId, quantity)}
    //     disabled={disableButton}
    //   />
    // </S.AddToCartSelection>
    React.createElement("div", null, React.createElement("ul", {
      className: "product__options list-unstyled"
    }, React.createElement("li", {
      className: "product__option"
    }, React.createElement("span", {
      className: "product__option-label"
    }, "Quantit\xE9"), React.createElement("div", {
      className: "quantity-control"
    }, React.createElement(S.VariantPicker, null, React.createElement(ProductVariantPicker, {
      productVariants: productVariants,
      onChange: onVariantPickerChange,
      selectSidebar: true,
      queryAttributes: queryAttributes,
      onAttributeChangeHandler: onAttributeChangeHandler
    })), React.createElement(QuantityInput, {
      quantity: quantity,
      maxQuantity: availableQuantity,
      disabled: isOutOfStock || isNoItemsAvailable,
      onQuantityChange: setQuantity,
      hideErrors: !variantId || isOutOfStock || isNoItemsAvailable,
      testingContext: "addToCartQuantity"
    })), React.createElement("div", null, isOutOfStock ? renderErrorMessage(intl.formatMessage(commonMessages.outOfStock), "outOfStock") : null, noPurchaseAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noPurchaseAvailable), "notAvailable"), purchaseAvailableDate && renderErrorMessage(intl.formatMessage(commonMessages.purchaseAvailableOn, {
      date: new Intl.DateTimeFormat("default", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }).format(purchaseAvailableDate),
      time: new Intl.DateTimeFormat("default", {
        hour: "numeric",
        minute: "numeric"
      }).format(purchaseAvailableDate)
    }), "timeRestrictedAvailability"), isLowStock && renderErrorMessage(intl.formatMessage(commonMessages.lowStock), "lowStockWarning"), isNoItemsAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noItemsAvailable), "noItemsAvailable"))), React.createElement("li", {
      className: ""
    }, React.createElement(AddToCartButton, {
      onSubmit: function onSubmit() {
        return onAddToCart(variantId, quantity);
      },
      disabled: disableButton
    }))))
  );
};

AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;