import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Consumer as MetaConsumer } from "./context";

var Consumer = function Consumer(_ref) {
  var children = _ref.children;
  return React.createElement(MetaConsumer, null, function (_ref2) {
    var title = _ref2.title,
        description = _ref2.description,
        image = _ref2.image,
        type = _ref2.type,
        url = _ref2.url,
        custom = _ref2.custom;
    return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("meta", {
      charSet: "utf-8"
    }), React.createElement("meta", {
      name: "author",
      content: "w3bs"
    }), React.createElement("link", {
      rel: "canonical",
      href: "https://miningmarket.fr/"
    }), React.createElement("meta", {
      httpEquiv: "X-UA-Compatible",
      content: "IE=edge"
    }), React.createElement("meta", {
      name: "viewport",
      content: "width=device-width,initial-scale=1"
    }), React.createElement("meta", {
      name: "description",
      content: "Votre source d'approvisionnement de mat\xE9riel destin\xE9 au mining de crypto-monnaies."
    }), React.createElement("link", {
      rel: "icon",
      type: "image/png",
      sizes: "48x48",
      href: "/assets/img/icons/favicon-48x48.png"
    }), React.createElement("link", {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: "/assets/img/icons/favicon-192x192.png"
    }), React.createElement("link", {
      rel: "icon",
      type: "image/png",
      sizes: "512x512",
      href: "/assets/img/icons/favicon-512x512.png"
    }), React.createElement("link", {
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "120x120",
      href: "/assets/img/icons/favicon-120x120.png"
    }), React.createElement("link", {
      rel: "apple-touch-icon",
      type: "image/png",
      sizes: "152x152",
      href: "/assets/img/icons/favicon-152x152.png"
    }), React.createElement("meta", {
      name: "twitter:card",
      content: "summary"
    }), React.createElement("meta", {
      name: "twitter:site",
      content: "@MiningMarket_21"
    }), React.createElement("meta", {
      name: "twitter:creator",
      content: "@MiningMarket_21"
    }), React.createElement("meta", {
      name: "twitter:title",
      content: "Mining Market"
    }), React.createElement("meta", {
      name: "twitter:description",
      content: "Votre source d'approvisionnement de mat\xE9riel destin\xE9 au mining de crypto-monnaies."
    }), React.createElement("meta", {
      name: "twitter:image",
      content: "https://miningmarket.fr/assets/img/og.jpg"
    }), React.createElement("link", {
      href: "/assets/vendor/magnific-popup/css/magnific-popup.css",
      rel: "stylesheet"
    }), React.createElement("link", {
      href: "/assets/vendor/slick/css/slick.css",
      rel: "stylesheet"
    }), React.createElement("link", {
      href: "/assets/css/bootstrap.css",
      rel: "stylesheet"
    }), React.createElement("link", {
      href: "/assets/css/font-awesome.css",
      rel: "stylesheet"
    }), React.createElement("link", {
      href: "/assets/css/mm.css",
      rel: "stylesheet"
    }), React.createElement("link", {
      href: "/assets/css/produit.css",
      rel: "stylesheet"
    }), React.createElement("link", {
      href: "/assets/css/home.css",
      rel: "stylesheet"
    })), React.createElement(Helmet, {
      title: title,
      meta: [{
        name: "description",
        content: description
      }, {
        property: "og:url",
        content: url
      }, {
        property: "og:title",
        content: title
      }, {
        property: "og:description",
        content: description
      }, {
        property: "og:type",
        content: type
      }, {
        property: "og:image",
        content: image
      }].concat(_toConsumableArray(custom))
    }), children);
  });
};

export default Consumer;