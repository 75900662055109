var slugUrl = ":slug([a-z-0-9]+)/:id([0-9]+)/";
export var baseUrl = "/";
export var searchUrl = "".concat(baseUrl, "search/");
export var categoryUrl = "".concat(baseUrl, "category/").concat(slugUrl);
export var collectionUrl = "".concat(baseUrl, "collection/").concat(slugUrl);
export var productUrl = "".concat(baseUrl, "product/").concat(slugUrl);
export var cartUrl = "".concat(baseUrl, "cart/:token?/");
export var checkoutLoginUrl = "".concat(baseUrl, "login/");
export var pageUrl = "".concat(baseUrl, "page/:slug/");
export var guestOrderDetailsUrl = "/order-history/:token/";
export var accountUrl = "".concat(baseUrl, "account/");
export var accountConfirmUrl = "".concat(baseUrl, "account-confirm/");
export var orderHistoryUrl = "".concat(baseUrl, "order-history/");
export var addressBookUrl = "".concat(baseUrl, "address-book/");
export var passwordResetUrl = "".concat(baseUrl, "reset-password/");
export var checkoutUrl = "".concat(baseUrl, "checkout/");
export var orderFinalizedUrl = "".concat(baseUrl, "order-finalized/");
export var accueilUrl = "".concat(baseUrl, "accueil/");
export var dataUrl = "".concat(baseUrl, "data/");
export var hostedUrl = "".concat(baseUrl, "hosted/");
export var categoryAsicUrl = "".concat(baseUrl, "category/asic/1/");
export var categoryGpuUrl = "".concat(baseUrl, "category/gpu/2/");
export var categoryRigPartsUrl = "".concat(baseUrl, "category/rig-parts/3/");
export var categoryBuiltRigsUrl = "".concat(baseUrl, "category/built-rigs/4/");
export var categoryAsicSparePartsUrl = "".concat(baseUrl, "category/asic-spare-parts/5/");