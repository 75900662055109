import React from "react";
export var getSvg = function getSvg(name) {
  switch (name) {
    case "hashrate":
      return React.createElement("svg", {
        width: "40",
        height: "40",
        id: "HASHRATE",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 500 500"
      }, React.createElement("path", {
        className: "svg-product-infos",
        d: "M275.71,263.42H222.27v23.19h53.44a11.6,11.6,0,0,0,0-23.19Z"
      }), React.createElement("path", {
        className: "svg-product-infos",
        d: "M277.94,224.1a11.58,11.58,0,0,0-11.5-11.69H222.27v23.18h44.17A11.54,11.54,0,0,0,277.94,224.1Z"
      }), React.createElement("path", {
        className: "svg-product-infos",
        d: "M454.2,268.07a18.57,18.57,0,0,0,0-37.13H417.1V166h37.1a18.57,18.57,0,0,0,0-37.13H416.63A50.8,50.8,0,0,0,370.7,83V45.38a18.55,18.55,0,0,0-37.1,0v37.1H268.67V45.41a18.57,18.57,0,0,0-37.13,0v37.1H166.61V45.41a18.57,18.57,0,0,0-37.13,0V83a50.79,50.79,0,0,0-45.92,45.92H46A18.55,18.55,0,0,0,46,166h37.1v64.93H46a18.57,18.57,0,0,0,0,37.13h37.1V333H46a18.57,18.57,0,0,0,0,37.13H83.59a50.79,50.79,0,0,0,45.92,45.92v37.58a18.55,18.55,0,1,0,37.1,0V416.5h64.93v37.1a18.57,18.57,0,0,0,37.13,0V416.5H333.6v37.1a18.57,18.57,0,0,0,37.13,0V416a50.8,50.8,0,0,0,45.92-45.93h37.58a18.55,18.55,0,1,0,0-37.1H417.1V268.07ZM277.94,314.22v14.14a13.92,13.92,0,0,1-27.83,0V314.44h-9.27v13.92a13.92,13.92,0,0,1-27.83,0V314.44h-4.65a13.93,13.93,0,0,1-13.92-13.92v-102a13.93,13.93,0,0,1,13.92-13.92H213V170.66a13.92,13.92,0,1,1,27.83,0v13.91h9.27V170.66a13.92,13.92,0,1,1,27.83,0v15.83A39,39,0,0,1,300,244.25a39.18,39.18,0,0,1-22.07,70Z"
      }));
      break;

    case "puissance":
      return React.createElement("svg", {
        width: "40",
        height: "40",
        id: "POWER",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 500 500"
      }, React.createElement("path", {
        className: "svg-product-infos",
        d: "M181.57,361.11c-33.4-21.87-52-61.78-52-113.83,0-38.74,17.2-67.47,32.34-92.79,12.22-20.54,22.93-38.72,27.3-62.15H78.56a51.07,51.07,0,0,0-51,51V319.64a51.09,51.09,0,0,0,51,51h95.66A80,80,0,0,0,181.57,361.11Z"
      }), React.createElement("path", {
        className: "svg-product-infos",
        d: "M440.21,148h-4.48v-4.65a51.08,51.08,0,0,0-51-51H314.8c30.34,36.07,56,82.55,56,147,0,52-24.41,109.61-77,131.37h90.9a51.06,51.06,0,0,0,51-51V315h4.48a32.52,32.52,0,0,0,32.48-32.48v-102A32.52,32.52,0,0,0,440.21,148Z"
      }), React.createElement("path", {
        className: "svg-product-infos",
        d: "M343,239.29c0,51.21-26.72,106.13-85.17,112.08,17.34-48.54,1.92-122,1.92-122,2.42,58.64-9.52,180-103.4,215a12,12,0,0,1-3.53.56,13.94,13.94,0,0,1-3.53-27.44c37.85-9.83,58.25-38.77,69.58-69.58-38.77-11.33-61.43-47-61.43-100.56,0-30.81,13.72-54,28.39-78.49,16.34-27.47,33.4-55.86,33.4-99.64A13.78,13.78,0,0,1,242.38,59l2.42,2.22C288.61,101.44,343,151.54,343,239.29Z"
      }));
      break;

    case "roi":
      return React.createElement("svg", {
        width: "40",
        height: "40",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 500 500"
      }, React.createElement("path", {
        className: "svg-product-infos",
        d: "M454.21,231.69a18.58,18.58,0,0,0-18.56,18.57c0,102.31-83.25,185.56-185.56,185.56S64.56,352.57,64.56,250.26,147.81,64.7,250.12,64.7c33.93,0,67.8,9.49,97,26l-24,24a18.54,18.54,0,0,0,13.11,31.67h81.63a18.57,18.57,0,0,0,18.56-18.56V46.16a18.54,18.54,0,0,0-31.67-13.11l-30.5,30.51a234.38,234.38,0,0,0-124.19-36c-122.77,0-222.66,99.89-222.66,222.66s99.89,222.66,222.66,222.66S472.78,373,472.78,250.26A18.57,18.57,0,0,0,454.21,231.69Z"
      }), React.createElement("path", {
        className: "svg-product-infos",
        d: "M386.55,171.41H333.34a39.3,39.3,0,0,1-26.72-68.25,158,158,0,1,0,79.93,68.25Zm-118,97.91h10.26a11.62,11.62,0,0,1,7.85,20.06,40.71,40.71,0,0,1,10.21,26.94v23.14H304a7.07,7.07,0,0,1,0,14.14H190.85a7.06,7.06,0,1,1,0-14.12h7.08V316.34a40.72,40.72,0,0,1,13.45-30.23l12-10.77a8.24,8.24,0,0,0,2-2.68v-3.34h.87a2.41,2.41,0,0,0,0-.54,8.72,8.72,0,0,0-2.94-6.57l-12-10.77a40.68,40.68,0,0,1-13.45-30.21V198.09h-7.08a7.07,7.07,0,1,1,0-14.14H304a7.06,7.06,0,1,1,0,14.12h-7.08v23.14a40.74,40.74,0,0,1-13.47,30.23l-12,10.77a8.74,8.74,0,0,0-2.93,6.56A2.46,2.46,0,0,0,268.56,269.32Z"
      }));
      break;

    case "ventilateurs":
      return React.createElement("svg", {
        width: "40",
        height: "40",
        id: "FANS",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 500 500"
      }, React.createElement("path", {
        className: "svg-product-infos",
        d: "M57.17,26.85A27.77,27.77,0,0,0,29.34,54.68V444.33a27.77,27.77,0,0,0,27.83,27.83H446.83a27.77,27.77,0,0,0,27.83-27.83V54.68a27.77,27.77,0,0,0-27.83-27.83ZM71.09,54.68A13.92,13.92,0,1,1,57.17,68.6,13.92,13.92,0,0,1,71.09,54.68Zm180.91,0c107.6,0,194.83,87.23,194.83,194.83S359.6,444.33,252,444.33,57.17,357.11,57.17,249.51,144.4,54.68,252,54.68Zm180.91,0A13.92,13.92,0,1,1,419,68.6,13.92,13.92,0,0,1,432.91,54.68Zm-153,30.66A27.83,27.83,0,0,0,252,113.17V166a83.51,83.51,0,0,1,65.24,31.42c22-4.75,40.49-9.12,59.25-13.54,24.89-5.86,14.84-27.54,4.87-39.79a166.94,166.94,0,0,0-95.93-58.22A28.58,28.58,0,0,0,279.89,85.34Zm-111,24.24c-7.68.65-16.16,5.63-22.28,10.6a167,167,0,0,0-58.22,95.95,27.92,27.92,0,0,0,27.29,33.43H168.5a83.5,83.5,0,0,1,31.42-65.23c-4.74-22-9.11-40.51-13.53-59.25C183.45,112.63,176.57,108.94,168.88,109.58ZM252,193.84a55.67,55.67,0,1,0,55.66,55.67A55.66,55.66,0,0,0,252,193.84Zm83.44,55.61A83.51,83.51,0,0,1,304,314.69c4.76,22,9.13,40.5,13.53,59.25,5.87,24.89,27.55,14.84,39.8,4.88a166.89,166.89,0,0,0,58.22-95.94,26.47,26.47,0,0,0,.53-5.54,27.81,27.81,0,0,0-27.83-27.89ZM186.71,301.64c-22,4.76-40.5,9.13-59.25,13.52-24.89,5.88-14.83,27.56-4.89,39.8a167,167,0,0,0,95.94,58.23,28.83,28.83,0,0,0,5.55.53,27.84,27.84,0,0,0,27.88-27.84V333.06A83.52,83.52,0,0,1,186.71,301.64ZM71.09,416.5a13.92,13.92,0,1,1-13.92,13.92A13.92,13.92,0,0,1,71.09,416.5Zm361.82,0A13.92,13.92,0,1,1,419,430.42,13.92,13.92,0,0,1,432.91,416.5Z"
      }));
      break;

    case "bruit":
      return React.createElement("svg", {
        width: "40",
        height: "40",
        id: "Layer_5",
        "data-name": "Layer 5",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 500 500"
      }, React.createElement("path", {
        className: "svg-product-infos",
        d: "M272.16,148.46A102.77,102.77,0,0,1,354.94,249.4H355v0h-.08a102.68,102.68,0,0,1-30.11,72.85,13.92,13.92,0,0,1-1.13,1,102.68,102.68,0,0,1-51.54,27.08,16.41,16.41,0,0,1-19.59-16.1V329.9a16.27,16.27,0,0,1,13-16.06,65.78,65.78,0,0,0,32.07-17.06c.27-.28.51-.57.79-.84a65.68,65.68,0,0,0,19.33-46.48h-.07v0h.07a66.06,66.06,0,0,0-52.22-64.33,16.28,16.28,0,0,1-13-16v-4.45a16.42,16.42,0,0,1,19.59-16.11Zm-159.51,39L196.28,96.3a10.94,10.94,0,0,1,19,7.4V395.53a10.94,10.94,0,0,1-19,7.39l-83.63-91.16H83.53c-54.92,0-54.69-49.93-54.69-49.93a182.56,182.56,0,0,1,0-24.76s-.31-49.62,54.69-49.62h29.12ZM270.31,26.69a16.4,16.4,0,0,0-17.73,16.36V47.4a16.37,16.37,0,0,0,15,16.35A186.58,186.58,0,0,1,438.34,249.4h-.06v0h.06a186.58,186.58,0,0,1-170.72,185.7,16.36,16.36,0,0,0-15,16.35v4.35a16.4,16.4,0,0,0,17.73,16.35A223.32,223.32,0,0,0,475.5,249.46h.08v0h-.08A223.34,223.34,0,0,0,270.31,26.69Zm.55,60.61a16.39,16.39,0,0,0-18.28,16.31V108A16.37,16.37,0,0,0,267,124.28a126.32,126.32,0,0,1,111,125.13H378v0h.08A126.32,126.32,0,0,1,267,374.64a16.35,16.35,0,0,0-14.46,16.3v4.36a16.39,16.39,0,0,0,18.28,16.31A163.07,163.07,0,0,0,415.23,249.48h.07v-.08h-.07A163.07,163.07,0,0,0,270.86,87.3Z"
      }));
      break;

    case "interface":
      return React.createElement("svg", {
        width: "40",
        height: "40",
        "data-name": "Layer 6",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 500 500"
      }, React.createElement("polygon", {
        className: "interface-svg-1",
        points: "318.2 357.07 318.2 316.06 345.12 316.06 345.12 275.05 386.13 275.05 386.13 140.47 113.87 140.47 113.87 275.05 154.87 275.05 154.87 316.06 181.8 316.06 181.8 357.07 318.2 357.07"
      }), React.createElement("line", {
        className: "interface-svg-1",
        x1: "174.72",
        y1: "172.82",
        x2: "174.72",
        y2: "240.9"
      }), React.createElement("line", {
        className: "interface-svg-1",
        x1: "224.9",
        y1: "172.82",
        x2: "224.9",
        y2: "240.9"
      }), React.createElement("line", {
        className: "interface-svg-1",
        x1: "275.09",
        y1: "172.82",
        x2: "275.09",
        y2: "240.9"
      }), React.createElement("line", {
        className: "interface-svg-1",
        x1: "325.28",
        y1: "172.82",
        x2: "325.28",
        y2: "240.9"
      }), React.createElement("path", {
        className: "interface-svg-1",
        d: "M444.83,399.8a44.66,44.66,0,0,1-44.54,44.53H99.71A44.66,44.66,0,0,1,55.17,399.8V99.21A44.66,44.66,0,0,1,99.71,54.68H400.29a44.66,44.66,0,0,1,44.54,44.53Z"
      }));
      break;

    default:
      return null;
  }
};