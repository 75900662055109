import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  text-decoration: underline;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  margin: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n\n  path {\n    transition: 0.3s;\n  }\n\n  &:hover {\n    path {\n      fill: ", ";\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  text-transform: ", ";\n  font-weight: ", ";\n  letter-spacing: ", ";\n  margin: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: ", ";\n  padding: ", ";\n  background-color: \"#151515\";\n  box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.25);\n  position: fixed;\n  top: ", ";\n  right: ", ";\n  border-left: 0.4rem solid;\n  border-color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";

var borderColors = function borderColors(theme) {
  return {
    action: theme.colors.error,
    error: theme.colors.error,
    neutral: theme.colors.primaryDark,
    success: theme.colors.success
  };
};

export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.message.width;
}, function (props) {
  return props.theme.message.padding;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return borderColors(props.theme)[props.status];
});
export var TopWrapper = styled.div(_templateObject2());
export var Title = styled.p(_templateObject3(), function (props) {
  return props.theme.message.titleTransform;
}, function (props) {
  return props.theme.message.titleWeight;
}, function (props) {
  return props.theme.message.letterSpacing;
}, function (props) {
  return props.theme.message.titleMargin;
});
export var CloseButton = styled.button(_templateObject4(), function (props) {
  return props.theme.colors.primary;
});
export var Content = styled.div(_templateObject5(), function (props) {
  return props.theme.message.contentMargin;
});
export var ActionButton = styled.button(_templateObject6(), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.typography.baseFontSize;
});