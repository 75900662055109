import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
export var QuantityInput = function QuantityInput(_ref) {
  var disabled = _ref.disabled,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      hideErrors = _ref.hideErrors,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isTooMuch = _useState2[0],
      setIsTooMuch = _useState2[1];

  var intl = useIntl();
  useEffect(function () {
    setIsTooMuch(!isNaN(quantity) && quantity > maxQuantity);
  }, [quantity, maxQuantity]);

  var handleQuantityChange = function handleQuantityChange(evt) {
    var newQuantity = parseInt(evt.target.value, 10);

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  var handleQuantityPlus = function handleQuantityPlus() {
    var newQuantity = quantity + 1;

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  var handleQuantityMinus = function handleQuantityMinus() {
    var newQuantity = quantity - 1;

    if (quantity !== newQuantity && newQuantity > 0) {
      onQuantityChange(newQuantity);
    }

    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  var quantityErrors = !hideErrors && isTooMuch ? [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }] : undefined;
  return React.createElement(React.Fragment, null, React.createElement("button", {
    className: "quantity-control__minus",
    onClick: handleQuantityMinus
  }, "\xA0"), React.createElement("input", {
    name: "quantity",
    type: "number",
    min: "1",
    max: maxQuantity,
    value: quantity.toString(),
    disabled: disabled,
    onChange: handleQuantityChange,
    style: {
      color: "white"
    }
  }), React.createElement("button", {
    className: "quantity-control__plus",
    onClick: handleQuantityPlus
  }, "\xA0"), React.createElement("br", null), React.createElement("small", null, quantityErrors ? "max : ".concat(maxQuantity) : ""));
};
QuantityInput.displayName = "QuantityInput";
export default QuantityInput;