import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { useAuth } from "@saleor/sdk";

var Notifications = function Notifications() {
  var alert = useAlert();
  var intl = useIntl();

  var _useAuth = useAuth(),
      authenticated = _useAuth.authenticated;

  var _React$useState = React.useState(),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      prevAuthenticated = _React$useState2[0],
      setPrevAuthenticated = _React$useState2[1];

  React.useEffect(function () {
    if (prevAuthenticated !== undefined && authenticated !== undefined) {
      if (!prevAuthenticated && authenticated) {
        alert.show({
          title: intl.formatMessage({
            defaultMessage: "You are now logged in",
            "id": "app.Notifications.1917823234"
          })
        }, {
          type: "success"
        });
      } else if (prevAuthenticated && !authenticated) {
        alert.show({
          title: intl.formatMessage({
            defaultMessage: "You are now logged out",
            "id": "app.Notifications.2378877294"
          })
        }, {
          type: "success"
        });
      }

      setPrevAuthenticated(authenticated);
    } else if (authenticated !== undefined) {
      setPrevAuthenticated(authenticated);
    }
  }, [authenticated]);
  return null;
};

export default Notifications;