import "../../../globalStyles/scss/index.scss";
import React from "react";
import * as appPaths from "../../../app/routes";

var Accueil = function Accueil() {
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "scroll-is--active"
  }, React.createElement("video", {
    autoPlay: true,
    playsInline: true,
    muted: true,
    loop: true
  }, React.createElement("source", {
    src: "/assets/vid/mm.mp4",
    type: "video/mp4"
  })), React.createElement("main", {
    className: "site-content site-content--center page",
    id: "wrapper"
  }, React.createElement("div", {
    className: "container container--large"
  }, React.createElement("div", {
    className: "page-heading page-heading--default text-center w-100",
    style: {
      marginBottom: '5vh'
    }
  }, React.createElement("img", {
    src: "assets/img/mm-logo-typo-light.svg",
    style: {
      maxWidth: "600px"
    },
    alt: "mm"
  }), React.createElement("br", null), React.createElement("p", {
    className: "glasscard center",
    style: {
      maxWidth: "650px",
      margin: "0 auto",
      fontSize: ".99rem"
    }
  }, "Bienvenue sur Mining Market !", React.createElement("br", null), "Votre source d'approvisionnement de mat\xE9riel destin\xE9 au mining de crypto-monnaies.")), React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    className: "col-sm-6 col-lg-3"
  }, React.createElement("div", {
    className: "mm-item mm-item--v4 mm-z1"
  }, React.createElement("a", {
    href: appPaths.categoryGpuUrl,
    className: "mm-item__thumbnail"
  }, React.createElement("div", {
    className: "mm-item__bg-holder"
  }, React.createElement("div", {
    className: "mm-item__bg"
  })), React.createElement("div", {
    style: {
      backgroundImage: "url(/assets/img/zones/gpu-zone.png)"
    },
    className: "mm-item__img-primary"
  })), React.createElement("span", {
    className: "mm-item__subtitle h6"
  }, "ZONE"), React.createElement("h2", {
    className: "mm-item__title"
  }, "GPU Mining"))), React.createElement("div", {
    className: "col-sm-6 col-lg-3"
  }, React.createElement("div", {
    className: "mm-item mm-item--v4 mm-z2"
  }, React.createElement("a", {
    href: appPaths.categoryAsicUrl,
    className: "mm-item__thumbnail"
  }, React.createElement("div", {
    className: "mm-item__bg-holder"
  }, React.createElement("div", {
    className: "mm-item__bg"
  })), React.createElement("div", {
    style: {
      backgroundImage: "url(/assets/img/zones/asic-zone.png)"
    },
    className: "mm-item__img-primary"
  })), React.createElement("span", {
    className: "mm-item__subtitle h6"
  }, "ZONE"), React.createElement("h2", {
    className: "mm-item__title"
  }, "ASIC Mining"))), React.createElement("div", {
    className: "col-sm-6 col-lg-3"
  }, React.createElement("div", {
    className: "mm-item mm-item--v4 mm-z3"
  }, React.createElement("a", {
    href: appPaths.hostedUrl,
    className: "mm-item__thumbnail"
  }, React.createElement("div", {
    className: "mm-item__bg-holder"
  }, React.createElement("div", {
    className: "mm-item__bg"
  })), React.createElement("div", {
    style: {
      backgroundImage: "url(/assets/img/zones/hosted-zone.png)"
    },
    className: "mm-item__img-primary"
  })), React.createElement("span", {
    className: "mm-item__subtitle h6"
  }, "ZONE"), React.createElement("h2", {
    className: "mm-item__title"
  }, "Hosted Mining"))), React.createElement("div", {
    className: "col-sm-6 col-lg-3"
  }, React.createElement("div", {
    className: "mm-item mm-item--v4 mm-z4"
  }, React.createElement("a", {
    href: appPaths.dataUrl,
    className: "mm-item__thumbnail"
  }, React.createElement("div", {
    className: "mm-item__bg-holder"
  }, React.createElement("div", {
    className: "mm-item__bg"
  })), React.createElement("div", {
    style: {
      backgroundImage: "url(/assets/img/zones/data-zone.png)"
    },
    className: "mm-item__img-primary"
  })), React.createElement("span", {
    className: "mm-item__subtitle h6"
  }, "ZONE"), React.createElement("h2", {
    className: "mm-item__title"
  }, "DATA Tools"))))))));
};

export { Accueil };