import React from "react";
import { Button } from "../../atoms";
export var AddToCartButton = function AddToCartButton(_ref) {
  var onSubmit = _ref.onSubmit,
      disabled = _ref.disabled;
  return React.createElement(Button, {
    testingContext: "addProductToCartButton",
    onClick: onSubmit,
    disabled: disabled
  }, React.createElement("div", {
    style: {
      textShadow: "0 0 2px #000"
    }
  }, "AJOUTER AU PANIER"));
};
AddToCartButton.displayName = "AddToCartButton";
export default AddToCartButton;